import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["classificationCheckbox", "toggleAllCheckbox"];

  connect() {
    this.updateToggleAllCheckbox();
    this.updateClassificationCheckboxes();
  }

  toggleAll() {
    const isChecked = this.toggleAllCheckboxTarget.checked;

    this.classificationCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = !isChecked;
      checkbox.disabled = isChecked;
    });
  }

  updateToggleAll() {
    this.updateToggleAllCheckbox();
    this.updateClassificationCheckboxes();
  }

  updateToggleAllCheckbox() {
    const allUnchecked = this.classificationCheckboxTargets.every(
      (checkbox) => !checkbox.checked
    );
    this.toggleAllCheckboxTarget.checked = allUnchecked;
  }

  updateClassificationCheckboxes() {
    const isAllUnsubscribed = this.toggleAllCheckboxTarget.checked;
    this.classificationCheckboxTargets.forEach((checkbox) => {
      checkbox.disabled = isAllUnsubscribed;
    });
  }
}
