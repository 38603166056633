import { useContext } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ControllerRenderProps } from "react-hook-form";

import { TimeZoneContext } from "~/context/timeZoneContext";

import { ControlsFormData } from ".";
import { DateRangeValue, DateValue, LookbackPeriodValue } from "./schema";
import { Errors } from "./fieldErrors";

dayjs.extend(utc);
dayjs.extend(timezone);

type ControlProps = {
  field: ControllerRenderProps<ControlsFormData, string>;
};

// Helper function to convert ISO date string to datetime-local format
const formatDateForInputUi = (isoString: string, timeZone: string): string => {
  // Change date from UTC format to store tz before showing in UI
  return dayjs.utc(isoString).tz(timeZone).format("YYYY-MM-DDTHH:mm");
};

// Helper function to convert datetime-local format to expected backend format
const formatDateForBackend = (dateString: string, timeZone: string): string => {
  // Change date from store tz to UTC format before sending to backend
  return dayjs.tz(dateString, timeZone).utc().format("YYYY-MM-DD HH:mm:ss");
};

export const DEFAULT_DATE_VALUE: DateValue = {
  date: dayjs().utc().toISOString(),
};

export const DateControl = ({ field }: ControlProps) => {
  const value = (field.value as DateValue) ?? DEFAULT_DATE_VALUE;
  const { timeZone } = useContext(TimeZoneContext);

  return (
    <>
      <input
        className="field-input w-64"
        type="datetime-local"
        {...field}
        value={formatDateForInputUi(value.date, timeZone)}
        onChange={(e) =>
          field.onChange({
            ...value,
            date: formatDateForBackend(e.target.value, timeZone),
          })
        }
      />
      <Errors fieldName={field.name} />
    </>
  );
};

export const DEFAULT_DATE_RANGE: DateRangeValue = {
  start_date: dayjs().utc().subtract(1, "week").startOf("day").toISOString(),
  end_date: dayjs().utc().endOf("day").toISOString(),
};

export const DateRangeControl = ({ field }: ControlProps) => {
  const value = (field.value as DateRangeValue) || DEFAULT_DATE_RANGE;
  const { timeZone } = useContext(TimeZoneContext);

  return (
    <>
      <div className="flex flex-col gap-1">
        <input
          className="field-input w-64"
          type="datetime-local"
          disabled={field.disabled}
          value={formatDateForInputUi(value.start_date, timeZone)}
          onChange={(e) =>
            field.onChange({
              ...value,
              start_date: formatDateForBackend(e.target.value, timeZone),
            })
          }
        />
        <input
          className="field-input w-64"
          type="datetime-local"
          disabled={field.disabled}
          value={formatDateForInputUi(value.end_date, timeZone)}
          onChange={(e) =>
            field.onChange({
              ...value,
              end_date: formatDateForBackend(
                e.target.value || new Date().toDateString(),
                timeZone
              ),
            })
          }
        />
      </div>
      <Errors fieldName={field.name} />
    </>
  );
};

export const DEFAULT_LOOKBACK_VALUE: LookbackPeriodValue = {
  number: 7,
  period: "day",
};

export const LookbackPeriodControl = ({ field }: ControlProps) => {
  const value = (field.value as LookbackPeriodValue) || DEFAULT_LOOKBACK_VALUE;

  return (
    <>
      <div className="flex gap-1">
        <input
          className="field-input w-20"
          type="number"
          disabled={field.disabled}
          value={value.number}
          onChange={(e) =>
            field.onChange({ ...value, number: parseInt(e.target.value) })
          }
        />
        <select
          className="field-input w-32"
          disabled={field.disabled}
          value={value.period}
          onChange={(e) =>
            field.onChange({
              ...value,
              period: e.target.value as LookbackPeriodValue["period"],
            })
          }
        >
          <option value="day">Days</option>
          <option value="month">Months</option>
          <option value="year">Years</option>
        </select>
      </div>
      <Errors fieldName={field.name} />
    </>
  );
};
