import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Prism from "prismjs";
import { LuAlertOctagon } from "react-icons/lu";

import { pluralize } from "../../utils/string-formatters";
import consumer from "../../channels/consumer";

import { TooltipProvider } from "../tooltip";
import TimeAgo from "../time-ago";
import StatusLabel from "./label";
import DownloadButton from "./downloadButton";
import RefreshButton from "./refreshButton";
import { Switch } from "../switch";

import { Audience } from "./types";
import {
  AUDIENCE_POLLING_INTERVAL,
  downloadCsv,
  fetchAudience,
  refreshAudience,
  toggleAudience,
} from "./data";

const AudienceDetails = ({ audience }: { audience: Audience }) => {
  const [activeAudience, setActiveAudience] = useState<Audience>(audience);
  const [isDownloadable, setIsDownloadable] = useState(
    audience.status !== "RUNNING" && audience.size !== 0
  );
  const [isRefreshable, setIsRefreshable] = useState(
    audience.status !== "RUNNING"
  );

  const refreshSyncStatus = async () => {
    try {
      // Sync status will be refreshed by GET call to audience endpoint
      await fetchAudience(activeAudience.store_id, activeAudience.id);
    } catch (error) {
      console.error("Error fetching audience:", error);
    }
  };

  const downloadAudience = async (store_id: string, audience_id: string) => {
    setIsDownloadable(false);
    await downloadCsv(store_id, audience_id);
    setIsDownloadable(true);
  }

  // Refresh audience sync status on initial load for all enabled audiences
  useEffect(() => {
    if (activeAudience.enabled) {
      refreshSyncStatus();
    }
  }, []); // Empty dependency array to run this effect once on initial mount;

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  useEffect(() => {
    const subscription = consumer.subscriptions.create(
      { channel: "AudienceChannel", audience_id: audience.id },
      {
        received(data: Audience) {
          const { status, size } = data;
          setActiveAudience({ ...audience, ...data });

          if (status) {
            setIsDownloadable(status !== "RUNNING" && size !== 0);
            setIsRefreshable(status !== "RUNNING");
          }
        },
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [audience]);

  useEffect(() => {
    // We only need to poll when an audience is running and enabled (ie. syncing via Census)
    let interval: number;
    if (activeAudience.status === "RUNNING" && activeAudience.enabled) {
      interval = setInterval(refreshSyncStatus, AUDIENCE_POLLING_INTERVAL);
    }

    return () => {
      clearInterval(interval);
    };
  }, [activeAudience.status, activeAudience.enabled]);

  const profiles = pluralize(activeAudience.size, "profile");

  return (
    <TooltipProvider>
      <div className="flex flex-col max-w-3xl gap-4 p-5 mx-auto border rounded">
        <div className="flex items-start justify-between">
          <h1 className="text-base font-medium">Audience Details</h1>
          {audience.is_klaviyo_setup ? (
            <Switch
              defaultChecked={activeAudience.enabled}
              disabled={!isRefreshable}
              onCheckedChange={() =>
                toggleAudience(
                  activeAudience.enabled,
                  activeAudience.store_id,
                  activeAudience.id
                )
              }
            />
          ) : null}
        </div>

        <table className="w-full text-sm border-separate table-fixed border-spacing-y-2">
          <tbody>
            <tr className="align-baseline">
              <td className="text-gray-500 w-[180px]">Name</td>
              <td className="break-words">{audience.name}</td>
            </tr>
            <tr className="align-baseline">
              <td className="text-gray-500">Description</td>
              <td className="break-words">{audience.description}</td>
            </tr>
          </tbody>
        </table>

        <div className="pt-8">
          <div className="flex flex-row justify-between pb-3">
            <p className="text-sm font-medium text-gray-600">Query</p>
          </div>

          <div className="flex flex-row flex-wrap items-baseline gap-2 text-sm text-gray-800">
            <div className="flex flex-col gap-2 w-[180px] min-w-max text-gray-500">
              <p>Status</p>
              <p>Size</p>
              <p>Last refreshed</p>
            </div>

            <div className="flex flex-col gap-2">
              <StatusLabel status={activeAudience.status} />

              <span
                className={clsx(
                  "text-gray-800",
                  activeAudience.size === 0 && "text-red-500"
                )}
              >
                {activeAudience.size === 0 ? "No profiles" : profiles}
              </span>
              <p className="flex gap-2">
                <span>{activeAudience.last_refreshed_at_formatted}</span>
                - <TimeAgo timestamp={activeAudience.last_refreshed_at} />
              </p>
            </div>

            <div className="flex flex-row self-end flex-grow gap-2 pt-2 sm:pt-0 sm:justify-end">
              <DownloadButton
                audience={activeAudience}
                onClick={() => downloadAudience(audience.store_id, audience.id)}
                disabled={!isDownloadable}
              />
              <RefreshButton
                audience={activeAudience}
                onClick={() => refreshAudience(audience.store_id, audience.id)}
                disabled={!isRefreshable}
              />
            </div>
          </div>

          {activeAudience.status === "FAILED" ? (
            <div className="flex flex-col w-full p-4 mt-5 text-red-700 rounded bg-red-50">
              <p className="flex flex-row items-center gap-3 text-sm font-medium">
                <LuAlertOctagon className="w-4 h-4 text-red-700" />
                Failed to refresh audience
              </p>
              <div className="flex flex-col gap-1 pt-2 text-sm empty:hidden">
                {activeAudience.failures}
              </div>
            </div>
          ) : null}

          <div className="pt-5">
            <pre className="rounded !my-0 !text-sm">
              <code className="language-sql !max-w-prose !break-words">
                {audience.query}
              </code>
            </pre>
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
};

export default AudienceDetails;
