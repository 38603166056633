import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller, FormProvider } from "react-hook-form";
import {
  VariableType,
  VariableValueMap,
  VariableControlType,
  createVariableSchema,
} from "./schema";
import { getDefaultValues, renderControl } from "./fieldMapping";
import {
  refreshReport,
  updateVariableValue,
} from "~/components/customReports/data";
import { useReportManagement } from "../../hooks/useReportManagement";

interface Props {
  report: {
    report_id: string;
    store_id: string;
    can_view_variables: boolean;
  };
  controls: VariableType[];
}

export type ControlsFormData = {
  [key: string]: VariableValueMap[VariableControlType];
};

export const Controls = ({ report, controls }: Props) => {
  const { disabledQueries } = useReportManagement();
  const { schema } = createVariableSchema(controls);
  const form = useForm<ControlsFormData>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(controls),
    mode: "onChange",
  });
  const isFieldDisabled =
    disabledQueries?.[report.report_id] || form.formState.isSubmitting;
  const isSubmitDisabled =
    !form.formState.isDirty ||
    form.formState.isSubmitting ||
    disabledQueries[report.report_id];

  const onSubmit = async (data: ControlsFormData) => {
    for (const variableId in form.formState.dirtyFields) {
      const response = await updateVariableValue(
        report.store_id,
        report.report_id,
        variableId,
        data[variableId]
      );

      if (response.error) {
        form.setError(variableId, {
          type: "manual",
          message: response.error,
        });
      }
    }

    if (Object.keys(form.formState.errors).length === 0) {
      form.reset(data);
      await refreshReport(report.store_id, report.report_id);
    }
  };

  if (controls?.length === 0 || !report.can_view_variables) return null;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2 bg-gray-100 p-4 rounded"
      >
        <table className="w-full table-fixed border-separate text-sm pb-6">
          <tbody className="flex flex-col gap-2">
            {controls?.map((controlItem) => (
              <tr
                key={controlItem.id}
                className="flex flex-row items-baseline gap-6"
              >
                <td className="w-full max-w-64 text-gray-500 truncate">
                  <label className="field-label">
                    {controlItem.display_name}
                  </label>
                </td>
                <td>
                  <Controller
                    name={controlItem.id}
                    control={form.control}
                    render={({ field }) =>
                      renderControl(controlItem, {
                        ...field,
                        disabled: isFieldDisabled,
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button
          className="btn w-fit h-auto"
          type="submit"
          disabled={isSubmitDisabled}
        >
          Save & Refresh
        </button>
      </form>
    </FormProvider>
  );
};
